import { render, staticRenderFns } from "./RequestBuyerSignup.vue?vue&type=template&id=733e4768&scoped=true&"
import script from "./RequestBuyerSignup.vue?vue&type=script&lang=js&"
export * from "./RequestBuyerSignup.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "733e4768",
  null
  
)

export default component.exports