<template>
  <div class="min-h-screen">
    <!-- Dark Header Section -->
    <div class="bg-gray-900 py-12 px-4 sm:px-6 lg:px-8 mb-8">
      <div class="max-w-3xl mx-auto">
        <!-- Logo Section -->
        <div class="flex align-items-center w-full space-x-4 md:-ml-10 mt-5 justify-center mb-8">
          <img 
            class="img-fluid md:h-auto logo-top md:pl-20 logo"
            :src="GET_COMPANY_SUBSCRIPTION_SETTING.full_logo" 
            alt="logo"
          >
        </div>

        <!-- Title Section -->
        <div class="text-center">
          <h2 class="text-4xl font-bold text-white mb-4">{{ $t('requestToBuyerTitle') }}</h2>
          <br>
          <br>
          <br>
          <p v-if="sellerLoginDescription" class="text-lg text-gray-300 whitespace-pre-line">
            {{ sellerLoginDescription }}
          </p>
          
          
        </div>
      </div>
    </div>

    <div class="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">

      <!-- Form Card -->
      <div class="bg-white shadow-xl rounded-2xl p-8">
        <ValidationObserver ref="form" v-slot="{ invalid, handleSubmit }">
          <form @submit.prevent="handleSubmit(processSubmit)">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
              <!-- Company Information Section -->
              <div class="md:col-span-2">
                <h3 class="text-xl font-semibold text-gray-900 border-b pb-2 mb-6">
                  {{ $t('companyInformation') }}
                </h3>
              </div>

              <ValidationProvider name="Company Name" :rules="{ required: true }" v-slot="{ errors }">
                <div class="form-group">
                  <label class="block text-sm font-medium text-gray-700 mb-1">
                    {{ $t('companyName') }} *
                  </label>
                  <input 
                    v-model="formData.company_name" 
                    type="text" 
                    class="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-shadow"
                    :placeholder="$t('enterCompanyName')"
                  />
                  <span class="text-red-500 text-sm">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>

              <ValidationProvider name="Organization Number" :rules="{ required: true }" v-slot="{ errors }">
                <div class="form-group">
                  <label class="block text-sm font-medium text-gray-700 mb-1">
                    {{ $t('organizationNumber') }} *
                  </label>
                  <input 
                    v-model="formData.organization_number" 
                    type="text" 
                    class="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-shadow"
                    :placeholder="$t('enterOrgNumber')"
                  />
                  <span class="text-red-500 text-sm">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>

              <ValidationProvider name="Contact Name" :rules="{ required: true }" v-slot="{ errors }">
                <div class="form-group">
                  <label class="block text-sm font-medium text-gray-700 mb-1">
                    {{ $t('contactName') }} *
                  </label>
                  <input 
                    v-model="formData.contact_name" 
                    type="text" 
                    class="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-shadow"
                    :placeholder="$t('enterContactName')"
                  />
                  <span class="text-red-500 text-sm">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>

              <ValidationProvider name="Email" :rules="{ required: true, email: true }" v-slot="{ errors }">
                <div class="form-group">
                  <label class="block text-sm font-medium text-gray-700 mb-1">
                    {{ $t('email') }} *
                  </label>
                  <input 
                    v-model="formData.email" 
                    type="email" 
                    class="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-shadow"
                    :placeholder="$t('enterEmail')"
                  />
                  <span class="text-red-500 text-sm">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>

              <ValidationProvider name="Phone" :rules="{ required: true}" v-slot="{ errors }">
                <div class="form-group">
                  <label class="block text-sm font-medium text-gray-700 mb-1">
                    {{ $t('phone') }} *
                  </label>
                  <input 
                    v-model="formData.phone" 
                    type="phone" 
                    class="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-shadow"
                    :placeholder="$t('enterPhone')"
                  />
                  <span class="text-red-500 text-sm">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>

              <ValidationProvider name="Billing Email" :rules="{ required: true, email: true }" v-slot="{ errors }">
                <div class="form-group">
                  <label class="block text-sm font-medium text-gray-700 mb-1">
                    {{ $t('billingEmail') }} *
                  </label>
                  <input 
                    v-model="formData.billing_email" 
                    type="email" 
                    class="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-shadow"
                    :placeholder="$t('enterBillingEmail')"
                  />
                  <span class="text-red-500 text-sm">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>

              <!-- Address Section -->
              <div class="md:col-span-2">
                <h3 class="text-xl font-semibold text-gray-900 border-b pb-2 mb-6">
                  {{ $t('addressInformation') }}
                </h3>
              </div>

              <ValidationProvider name="Address" :rules="{ required: true }" v-slot="{ errors }">
                <div class="form-group md:col-span-2">
                  <label class="block text-sm font-medium text-gray-700 mb-1">
                    {{ $t('billingAddress') }} *
                  </label>
                  <input 
                    v-model="formData.address" 
                    type="text" 
                    class="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-shadow"
                    :placeholder="$t('enterAddress')"
                  />
                  <span class="text-red-500 text-sm">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>

              <ValidationProvider name="City" :rules="{ required: true }" v-slot="{ errors }">
                <div class="form-group">
                  <label class="block text-sm font-medium text-gray-700 mb-1">
                    {{ $t('city') }} *
                  </label>
                  <input 
                    v-model="formData.city" 
                    type="text" 
                    class="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-shadow"
                    :placeholder="$t('enterCity')"
                  />
                  <span class="text-red-500 text-sm">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>

              <ValidationProvider name="ZIP Code" :rules="{ required: true }" v-slot="{ errors }">
                <div class="form-group">
                  <label class="block text-sm font-medium text-gray-700 mb-1">
                    {{ $t('zipCode') }} *
                  </label>
                  <input 
                    v-model="formData.zip_code" 
                    type="text" 
                    class="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-shadow"
                    :placeholder="$t('enterZipCode')"
                  />
                  <span class="text-red-500 text-sm">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>

              <ValidationProvider name="Message" v-slot="{ errors }" class="md:col-span-2">
                <div class="form-group">
                  <label class="block text-sm font-medium text-gray-700 mb-1">
                    {{ $t('message') }}
                  </label>
                  <textarea 
                    v-model="formData.message" 
                    class="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-shadow"
                    rows="4"
                    :placeholder="$t('enterMessage')"
                  ></textarea>
                  <span class="text-red-500 text-sm">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>

              <div class="form-group md:col-span-2">
                <button 
                  type="submit"
                  class="w-full bg-[#1E2C41] text-white px-8 py-3 rounded-lg hover:bg-[#151f2d] focus:outline-none focus:ring-2 focus:ring-[#1E2C41] focus:ring-offset-2 transition-colors disabled:opacity-50"
                  :disabled="processing"
                >
                  {{ processing ? $t('submitting') : $t('submitRequest') }}
                </button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'



export default {
  name: 'RequestBuyerForm',
  
  components: {
    ValidationProvider,
    ValidationObserver
  },

  computed: {
    ...mapGetters({
      GET_COMPANY_SUBSCRIPTION_SETTING: 'portal/GET_COMPANY_SUBSCRIPTION_SETTING'
    }),

    sellerLoginDescription() {
      console.log('Computed seller login description:', this.GET_COMPANY_SUBSCRIPTION_SETTING?.seller_login_description);
      return this.GET_COMPANY_SUBSCRIPTION_SETTING?.seller_login_description || '';
    }
  },

  
  data() {
    return {
      processing: false,
      formData: {
        company_name: '',
        organization_number: '',
        address: '',
        city: '',
        zip_code: '',
        email: '',
        phone: '',
        billing_email: '',
        contact_name: '',
        message: ''
      }
    }
  },

  methods: {
    validateForm() {
      if (!this.formData.company_name || !this.formData.organization_number || 
          !this.formData.address || !this.formData.city || !this.formData.zip_code || !this.formData.phone || 
          !this.formData.email || !this.formData.billing_email || !this.formData.contact_name) {
        this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
        return false
      }

      
      if (!this.$services.helpers.validateEmailAddress(this.formData.email)) {
        this.$services.helpers.notification(this.$t('invalidEmail'), 'error', this)
        return false
      }

      if (!this.$services.helpers.validateEmailAddress(this.formData.billing_email)) {
        this.$services.helpers.notification(this.$t('invalidBillingEmail'), 'error', this)
        return false
      }

      return true
    },

    async processSubmit() {
      if (!this.validateForm()) return

      this.processing = true

      const jsonPayload = {
        company_name: this.formData.company_name,
        organization_number: this.formData.organization_number,
        address: this.formData.address,
        city: this.formData.city,
        zip_code: this.formData.zip_code,
        email: this.formData.email,
        phone: this.formData.phone,
        billing_email: this.formData.billing_email,
        contact_name: this.formData.contact_name,
        message: this.formData.message || ''
      }

      try {
        await this.$store.dispatch('auth/requestBuyerSignup', jsonPayload)
        this.$services.helpers.notification(this.$t('requestSubmitted'), 'success', this)
        setTimeout(() => {
          this.$router.go(-1)
        }, 3500)
      } catch (error) {
          // Show the message from backend if available
          const errorMessage = error.response?.data?.message || 
                              error.response?.data || 
                              this.$t('serverError')
          this.$services.helpers.notification(errorMessage, 'error', this)
      } finally {
        this.processing = false
      }
    }
  }
}
</script>

<style scoped>
.logo {
  height: 95px;
}

@media only screen and (max-width: 600px) {
  .logo {
    height: 48px;
  }
}